import React from "react"
import Layout from "../../componets/Layout"

export default function projects() {
  return (
    <Layout>
      <div>
        <h1>My projects</h1>
      </div>
    </Layout>
  )
}
